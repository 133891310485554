import {
    Image,
    Avatar,
    useStyles,
    Subheader,
    Carousel,
    pxToRem,
} from '@archipro-design/aria';

import type { ProfessionalBannersData } from '~/modules/professional/page/OverviewPage';

import * as S from './BannerAndLogo.style';
import { useCurrentTabBannerImage } from '~/modules/professional/hook/use-current-tab-banner-image';

import {
    MEDIA_CONIDTION_SIZES_LAPTOP_ONLY,
    MEDIA_CONIDTION_SIZES_LARGE_SCREEN,
} from '~/modules/images/config/sizes-constant';

import { useEffect, useRef, useState } from 'react';
import type SwiperCore from 'swiper';
import BannerAndLogoHeaderProgress from './BannerAndLogoProgress';
import {
    BANNER_CAROUSEL_DELAY,
    BANNER_CAROUSEL_UPDATE_RATE,
} from '~/modules/professional/config/constants';
import type { AwardProps } from '@modules/page-builder/component/detail/banner-awards/types';
import BannerAwards from '@modules/page-builder/component/detail/banner-awards/BannerAwards';
import { hasFeature, useAppData } from '@modules/root';

import { ProfessionalHeroBannerContent } from '~/modules/professional/component/overview-tab/banner-and-logo/ProfessionalHeroBannerContent';
import type { PrimaryCTAResult } from '~/modules/professional/util/professional-cta-helper';
import { ArrowLeftLine, ArrowRightLine } from '@archipro-design/icons';

let animationTimer = 0;

export const BannerAndLogo: React.FC<{
    banners: ProfessionalBannersData;
    awards?: AwardProps[];
    professional: {
        CompanyLogo: string;
        LogoBackgroundColor: string;
        Title: string;
        Link: string;
        Website: string;
        ID: number;
        Content?: string;
    };
    hideCarouselArrow?: boolean;
    hideLogo?: boolean;
    openEnquiryModal: (changeUrl?: boolean) => void;
    primaryCTA: PrimaryCTAResult;
}> = ({
    banners,
    awards,
    professional,
    hideLogo = false,
    openEnquiryModal,
    primaryCTA,
    hideCarouselArrow = true,
}) => {
    const bannerImage = useCurrentTabBannerImage(banners);

    const hasLogo = !!professional.CompanyLogo;

    const { user } = useAppData();
    const enableAwardBadges = hasFeature(user.FeatureFlags, 'award_badges');

    const [headerSwiper, setHeaderSwiper] = useState<SwiperCore | null>(null);
    const ref = useRef(null);
    const prevRef = useRef<HTMLButtonElement | null>(null);
    const nextRef = useRef<HTMLButtonElement | null>(null);
    const bannerHeight = 680;

    const images = banners.map((b, i) => {
        return {
            src: b?.libraryImageFile?.DetailBannerStandard?.Desktop,
            key: i,
        };
    });

    const delay = BANNER_CAROUSEL_DELAY;
    const updateRate = BANNER_CAROUSEL_UPDATE_RATE;
    const updateBlock = (updateRate / delay) * 100;

    const [currentTile, setCurrentTile] = useState<number>(0);
    const [currentProgress, setCurrentProgress] = useState<number>(0);

    const startAnimationTimer = () => {
        animationTimer = window.setInterval(() => {
            setCurrentProgress((num) => num + updateBlock);
        }, updateRate);
    };

    // Start animate timer
    useEffect(() => {
        if (animationTimer) {
            window.clearInterval(animationTimer);
        }
        setCurrentProgress(0);
        startAnimationTimer();

        return () => {
            window.clearInterval(animationTimer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay]);

    useEffect(() => {
        if (headerSwiper) {
            setCurrentProgress(0);
            setCurrentTile(headerSwiper.realIndex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerSwiper?.realIndex]);

    const { css } = useStyles({
        hasBannerImage: !!bannerImage,
        hasLogo: hasLogo,
    });

    return (
        <div className={css(S.Container)}>
            {images.length > 1 ? (
                <div className={css(S.BannerWrap)}>
                    <Carousel
                        getSwiperApi={(swiper) => {
                            setHeaderSwiper(swiper as SwiperCore);
                        }}
                        ref={ref}
                        aria-roledescription={'carousel'}
                        items={images.map((img, key) => {
                            if (img.src === null || img.src === undefined)
                                return null;
                            return (
                                <Image
                                    key={`bannerImgCarousel${key}`}
                                    src={img.src}
                                    width={'100%'}
                                    height={819}
                                    layout={`fill`}
                                    alt={
                                        key === 0
                                            ? `Hero Banner for ${professional.Title}`
                                            : `Supporting Image ${key} for ${professional.Title}`
                                    }
                                    objectFit={`cover`}
                                    loading={img.key === 0 ? 'eager' : 'lazy'}
                                    sizes={`100vw`}
                                />
                            );
                        })}
                        autoplay={{
                            disableOnInteraction: false,
                            delay,
                        }}
                        navigation={true}
                        loop={true}
                        effect={'fade'}
                        fadeEffect={{
                            crossFade: true,
                        }}
                        hidePaddles={true}
                        paddleNext={{
                            ref: nextRef,
                        }}
                        paddlePrevious={{
                            ref: prevRef,
                        }}
                        variables={{
                            overlayPadding: pxToRem(0),
                            overlayInitialOpacity: 1,
                            containerWidth: '100%',
                            containerHeight: pxToRem(bannerHeight),
                        }}
                    />

                    <BannerAndLogoHeaderProgress
                        progress={currentProgress}
                        total={images.length}
                        current={currentTile}
                    />
                    {!hideCarouselArrow && (
                        <>
                            <div
                                onClick={() => {
                                    headerSwiper?.slidePrev();
                                }}
                                className={css(S.PaddleBase, S.LeftPaddle)}
                            >
                                <div>
                                    <ArrowLeftLine />
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    headerSwiper?.slideNext();
                                }}
                                className={css(S.PaddleBase, S.RightPaddle)}
                            >
                                <div>
                                    <ArrowRightLine />
                                </div>
                            </div>
                        </>
                    )}
                    {enableAwardBadges && awards && awards.length > 0 && (
                        <BannerAwards
                            awards={awards}
                            heading={'Award winning professional'}
                            professionalUrl={professional.Link}
                        />
                    )}
                </div>
            ) : (
                <div className={css(S.BannerWrap)}>
                    {bannerImage !== null ? (
                        <Image
                            src={bannerImage}
                            width={'100%'}
                            height={bannerHeight}
                            layout={`fill`}
                            objectFit={`cover`}
                            loading={'eager'}
                            alt={`Hero Banner for ${professional.Title}`}
                            sizes={`${MEDIA_CONIDTION_SIZES_LARGE_SCREEN} 1920px,${MEDIA_CONIDTION_SIZES_LAPTOP_ONLY} 95vw, 100vw`}
                        />
                    ) : (
                        <Subheader variant="03">Banner image</Subheader>
                    )}
                    {awards && awards.length > 0 && (
                        <BannerAwards awards={awards} />
                    )}
                </div>
            )}

            <ProfessionalHeroBannerContent
                professional={professional}
                openEnquiryModal={openEnquiryModal}
                primaryCTA={primaryCTA}
            />

            {!hideLogo && (
                <div className={css(S.LogoWrap)}>
                    <Avatar
                        image={{
                            src: professional.CompanyLogo ?? '',
                            alt: `${professional.Title} company logo`,
                        }}
                        initials={'Logo'}
                        size={214}
                        shape={'square'}
                        imageFit={'contain'}
                        styles={{
                            borderWidth: 0,
                        }}
                        variables={{
                            avatarImageBackgroundColor:
                                professional.LogoBackgroundColor ?? 'white',

                            borderRadius: '0',
                        }}
                    />
                </div>
            )}
        </div>
    );
};
