/* eslint-disable react/jsx-no-target-blank */
import { Button as RoccoButton } from '@rocco/components/button';
import { ArrowRight } from 'lucide-react';
import { useWebsiteReferralTracking } from '~/modules/tracking/util/websiteTracking';
import { useTracker } from '@archipro-website/tracker';
import { trackEnquireReveal } from '~/modules/tracking/util/trackEnquireReveal';
import type { PrimaryCTAResult } from '~/modules/professional/util/professional-cta-helper';

const HIDE_CTA_AND_CONTENT = true;
export const ProfessionalHeroBannerContent = ({
    professional,
    openEnquiryModal,
    primaryCTA,
}: {
    professional: {
        Title: string;
        Content?: string;
        Website: string;
        ID: number;
    };
    openEnquiryModal: (changeUrl?: boolean) => void;
    primaryCTA: PrimaryCTAResult;
}) => {
    const { Title, Website, ID, Content } = professional;
    const {
        referralTrackingURL,
        referralTrackingClass,
        referralTrackingParams,
    } = useWebsiteReferralTracking(Website, {
        libraryItemID: ID,
        professionalTitle: Title,
    });

    const tracker = useTracker();

    return (
        <div className="flex w-full md:w-[70rem] flex-col px-4 md:pl-12 absolute z-sticky bottom-0 left-0 pt-[15.125rem] md:pt-32 md:mt-[10rem]">
            <h1 className="max-w-full pb-12 md:pb-[3.2rem] text-[2.75rem] leading-[116%] md:text-20 md:leading-[110%] tracking-[-0.15rem] text-white m-0">
                {Title}
            </h1>

            {!HIDE_CTA_AND_CONTENT && Content && Content.length > 0 && (
                <div className="pr-24 md:pr-0 pb-12 md:pb-[3.2rem] md:max-w-[35.625rem]">
                    <div
                        className="text-4 md:text-5 leading-[130%] tracking-[0.025rem] text-white line-clamp-2 h-[2.6em]"
                        dangerouslySetInnerHTML={{
                            __html: Content ?? '',
                        }}
                    />
                </div>
            )}

            {!HIDE_CTA_AND_CONTENT && (
                <div className="flex gap-9 items-center">
                    <RoccoButton
                        color="primary"
                        size={'larger'}
                        weight={'default'}
                        onClick={() => {
                            openEnquiryModal(true);
                            trackEnquireReveal(tracker, {
                                professionalName: Title,
                                itemID: ID,
                                itemType: 'professional',
                                source: 'EnquiryModal',
                            });
                        }}
                    >
                        {primaryCTA.primaryCTALabel}
                        <ArrowRight className="ml-2.5 w-5" />
                    </RoccoButton>

                    {Website && (
                        <RoccoButton
                            color="transparent"
                            inverted={true}
                            asChild
                            size={'compact'}
                            shape={'square'}
                        >
                            <a
                                href={referralTrackingURL}
                                className={referralTrackingClass}
                                target="_blank"
                                {...referralTrackingParams}
                                style={{
                                    textDecoration: 'underline',
                                    textUnderlineOffset: '0.3rem',
                                    letterSpacing: '0.035rem',
                                }}
                            >
                                {'View website'}
                            </a>
                        </RoccoButton>
                    )}
                </div>
            )}
        </div>
    );
};
