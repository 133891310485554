import { useStyles } from '@archipro-design/aria';
import * as S from './HeaderSection.style';
import SubRouteNavigation from '../sub-route-navigation/SubRouteNavigation';
import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import { useMatchesHandleData, useRootData } from '@modules/root';
import { useRef, useState, useEffect } from 'react';
import { Link, useLoaderData } from '@remix-run/react';
import type { ProfessionalDetailRootLoaderData } from '../../page/ProfessionalPage';
import type { HeaderSectionProps } from './HeaderSection';
import { useSize } from 'ahooks';
import {
    ENQUIRE_MODAL_SHOWUP_KEY,
    ENQUIRE_MODAL_SHOWUP_KEY_VALUE,
} from '~/modules/enquiry/constants';
import { BannerAndLogo } from '@modules/professional/component/overview-tab/banner-and-logo/BannerAndLogo';
import { BannerSegmentEnum } from 'generated/graphql';
import { Button as RoccoButton } from '@rocco/components/button';
import { ChevronDown } from '@archipro-design/icons';
import { PROFESSIONAL_SECTIONS_ANCHOR } from '../../util/constants';

import { useWebsiteReferralTracking } from '~/modules/tracking/util/websiteTracking';

const ENABLE_SCROLL_INDICATOR = false;

export const HeaderSectionDesktop = ({
    openEnquiryModal,
    primaryCTA,
    banners,
}: HeaderSectionProps) => {
    const { linkedProfiles } =
        useLoaderData<ProfessionalDetailRootLoaderData>();
    const {
        Title,
        Link: professionalLink,
        LibraryLocations,

        CompanyLogo,
        LogoBackgroundColor,
        Website,
        ID,
        Content,
    } = useProfessionalInitialRouteData();
    const isBMPreview = useMatchesHandleData('isBMPreview', false);
    const hasLinkedLocation =
        linkedProfiles?.linkedProfilesList.some((p) =>
            p.LibraryLocations.some((l) => !l.LocationHidden)
        ) ?? false;

    const IS_CLIENT_SIDE = typeof window !== 'undefined';
    const innerRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const sentinelRef = useRef<HTMLDivElement>(null);
    const [isSticky, setIsSticky] = useState(false);
    const [isHidden, setIsHidden] = useState(!ENABLE_SCROLL_INDICATOR);
    const [chevronOpacity, setChevronOpacity] = useState(1);

    const screenSize = {
        width: IS_CLIENT_SIDE ? window.innerWidth : 0,
        height: IS_CLIENT_SIDE ? window.innerHeight : 0,
    };
    const heroSize = useSize(innerRef);

    const { css } = useStyles({
        heroSize,
        screenSize,
        isBMPreview,
        isSticky,
    });

    const { env } = useRootData();
    const url = new URL(professionalLink, `https://${env.host}`);
    url.searchParams.set(
        ENQUIRE_MODAL_SHOWUP_KEY,
        ENQUIRE_MODAL_SHOWUP_KEY_VALUE
    );
    const professionalPageWithEnquiry = url.pathname + url.search;

    const {
        referralTrackingURL,
        referralTrackingClass,
        referralTrackingParams,
    } = useWebsiteReferralTracking(Website ?? undefined, {
        libraryItemID: ID ?? 0,
        professionalTitle: Title ?? '',
    });

    const excludeAboutBanner = banners?.filter(
        (b) => b.bannerSegment !== BannerSegmentEnum.Aboutus
    );

    const bannerRef = useRef(null);

    useEffect(() => {
        if (!sentinelRef.current || !bannerRef.current) return;

        // Set up intersection observer for sticky header
        const observer = new IntersectionObserver(
            ([entry]) => {
                // the sentinelRef triggers the event when going out of the screen while scrolling up
                // checking intersectionRect to make sure we're in the right position
                if ((entry?.intersectionRect?.x ?? 0) > 0) {
                    setIsSticky(!entry?.isIntersecting);
                }
            },
            {
                threshold: [0.01],
                rootMargin: '0px',
            }
        );

        observer.observe(sentinelRef.current);

        // Handle scroll and resize for chevron visibility
        const handleScrollResize = () => {
            if (!ENABLE_SCROLL_INDICATOR) return;

            const projectsSection = document.querySelector(
                `#${PROFESSIONAL_SECTIONS_ANCHOR}`
            );
            const offset = 80;

            if (projectsSection) {
                const projectsRect = projectsSection.getBoundingClientRect();
                setIsHidden(projectsRect.top < window.innerHeight + 50);
                const fadeDistance = window.innerHeight * 0.8; // Adjust percentage as needed
                const newOpacity = Math.max(
                    0,
                    (projectsRect.top - window.innerHeight + offset) /
                        fadeDistance
                );
                if (window.scrollY < 5) {
                    setChevronOpacity(1);
                } else {
                    setChevronOpacity(newOpacity);
                }
            }
        };

        window.addEventListener('scroll', handleScrollResize);
        window.addEventListener('resize', handleScrollResize);

        handleScrollResize();

        // Cleanup function
        return () => {
            observer.disconnect();
            window.removeEventListener('scroll', handleScrollResize);
            window.removeEventListener('resize', handleScrollResize);
        };
    }, []);

    const scrollToProjects = () => {
        const projectsSection = document.querySelector(
            `#${PROFESSIONAL_SECTIONS_ANCHOR}`
        );
        if (projectsSection) {
            projectsSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            {excludeAboutBanner && (
                <div ref={bannerRef} className="relative w-full">
                    <BannerAndLogo
                        banners={excludeAboutBanner}
                        awards={[]}
                        professional={{
                            CompanyLogo: CompanyLogo ?? '',
                            LogoBackgroundColor: LogoBackgroundColor ?? '',
                            Title: Title ?? '',
                            Link: professionalLink ?? '',
                            Website: Website ?? '',
                            ID: ID ?? 0,
                            Content: Content ?? '',
                        }}
                        hideLogo={true}
                        openEnquiryModal={openEnquiryModal}
                        primaryCTA={primaryCTA}
                    />

                    {!isHidden && (
                        <div
                            className="fixed bottom-10 z-fixed w-[100%] cursor-pointer duration-500 will-change-[transform,opacity]"
                            onClick={scrollToProjects}
                            style={{ opacity: chevronOpacity }}
                        >
                            <div className="flex justify-center">
                                <ChevronDown className=" animate-bounce text-white [&_path]:fill-white transition-opacity duration-500 ease-in-out" />
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div
                ref={wrapperRef}
                className={css(S.ProfessionalHeaderFixedWrapper)}
            >
                <div
                    ref={sentinelRef}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '1px',
                    }}
                />
                <div className={css(S.ProfessionalHeaderContainer)}>
                    <div className={css(S.PageHeader)}>
                        <div ref={innerRef} className={css(S.PageHeaderInner)}>
                            <div className={css(S.LeftPane)}>
                                <SubRouteNavigation>
                                    {!LibraryLocations.some(
                                        (l) => !l.LocationHidden
                                    ) &&
                                        !hasLinkedLocation && (
                                            <RoccoButton
                                                color="secondary"
                                                asChild
                                            >
                                                <Link
                                                    to={
                                                        professionalPageWithEnquiry
                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        openEnquiryModal(true);
                                                    }}
                                                    className="text-sm leading-6.5 tracking-0 md:text-base md:tracking-0"
                                                >
                                                    Contact
                                                </Link>
                                            </RoccoButton>
                                        )}
                                </SubRouteNavigation>
                            </div>
                            <div className={css(S.RightPane)}>
                                <div className={css(S.RightPaneInner)}>
                                    {/* TODO: build out a share function */}
                                    {/* <RoccoButton color="secondary">
                                        Share Pro
                                    </RoccoButton> */}
                                    {referralTrackingURL && (
                                        <RoccoButton color="secondary" asChild>
                                            <a
                                                href={referralTrackingURL}
                                                target="_blank"
                                                rel="noreferrer"
                                                className={
                                                    referralTrackingClass
                                                }
                                                {...referralTrackingParams}
                                            >
                                                Visit Website
                                            </a>
                                        </RoccoButton>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
