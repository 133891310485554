import {
    Container,
    useAppDisplayModeContext,
    useStyles,
    useTheme,
} from '@archipro-design/aria';

import HeaderSection from '../header-section/HeaderSection';

import * as S from './PageLayout.style';
import React, { useEffect, useState } from 'react';

import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import { GetInTouchSectionMobile } from '../get-in-touch-section/GetInTouchSection.mobile';
import { MobileBreadcrumb } from '../breadcrumb/Breadcrumb.mobile';
import { useLocation } from '@remix-run/react';
import type { PrimaryCTAResult } from '@modules/professional/util/professional-cta-helper';
import { BannerSegmentEnum } from 'generated/graphql';
import { useMatchesHandleData } from '~/modules/root';
import type { AwardProps } from '@modules/page-builder/component/detail/banner-awards/types';
import { PROFESSIONAL_SECTIONS_ANCHOR } from '../../util/constants';
import type { ProfessionalDetailsInitialFragment } from 'generated/graphql';

// Map the banner images to the format expected by the HeaderSection component
const mapBannerImages = (
    bannerImages: ProfessionalDetailsInitialFragment['BannerImages']
) =>
    bannerImages
        .filter((b) => b.BannerSegment !== BannerSegmentEnum.Aboutus)
        .map((banner) => ({
            id: banner.ID,
            bannerSegment: banner.BannerSegment,
            memberSortOrder: banner.MemberSortOrder,
            libraryImageFile: {
                __typename: 'LibraryImageFile' as const,
                DetailBannerStandard: banner.LibraryImageFile
                    ?.DetailBannerStandard ?? {
                    __typename: 'ResponsiveImage' as const,
                    Mobile: null,
                    Desktop: null,
                },
                DetailPageSegmentBanner: banner.LibraryImageFile
                    ?.DetailPageSegmentBanner ?? {
                    __typename: 'ResponsiveImage' as const,
                    Mobile: null,
                    Desktop: null,
                },
            },
        }));

const PageLayout: React.FC<
    React.PropsWithChildren & {
        primaryCTA: PrimaryCTAResult;
        awards?: AwardProps[];
        openEnquiryModal: (changeUrl?: boolean) => void;
    }
> = ({ children, primaryCTA, awards, openEnquiryModal }) => {
    const theme = useTheme();
    const { mobile } = useAppDisplayModeContext();

    const disableMobileUserScaling = useMatchesHandleData<boolean>(
        'disableMobileUserScaling',
        false
    );
    const noUserScalable = mobile && disableMobileUserScaling;

    const { css } = useStyles({ noUserScalable });

    const {
        Breadcrumbs: breadcrumbs,
        CompanyLogo: companyLogo,
        LogoBackgroundColor: logoBackgroundColor,
        ID: professionalId,
        BannerImages,
    } = useProfessionalInitialRouteData();

    const [bannerImageList, setBannerImageList] = useState(
        mapBannerImages(BannerImages)
    );

    useEffect(() => {
        setBannerImageList(mapBannerImages(BannerImages));
    }, [BannerImages]);

    const location = useLocation();

    const showEnquiryForm = !location.pathname.includes('contact');

    if (professionalId === undefined) {
        throw new Error('Professional ID can not be undefined.');
    }

    return (
        <div className={css(S.Page)}>
            <Container
                variables={{
                    background:
                        theme.siteVariables.colorScheme.default?.background,
                }}
            >
                <HeaderSection
                    banners={bannerImageList}
                    awards={awards}
                    breadcrumbs={breadcrumbs}
                    companyLogo={companyLogo}
                    professionalId={professionalId}
                    logoBackgroundColor={logoBackgroundColor}
                    primaryCTA={primaryCTA}
                    openEnquiryModal={openEnquiryModal}
                />
                <div
                    className={`pt-0 md:pt-8 ${mobile ? 'scroll-mt-63' : 'scroll-mt-24'}`}
                    id={PROFESSIONAL_SECTIONS_ANCHOR}
                >
                    {children}
                </div>
                {mobile && (
                    <div className={css(S.PageEndWrapper)}>
                        {showEnquiryForm && <GetInTouchSectionMobile />}
                        <MobileBreadcrumb breadcrumbs={breadcrumbs} />
                    </div>
                )}
            </Container>
        </div>
    );
};

export default PageLayout;
