import { getSubRoutes } from './utils';
import { useMatches, Link, useLoaderData } from '@remix-run/react';
import { useProfessionalInitialRouteData } from '../../hook/use-professional-initial-route-data';
import { useTracker } from '@archipro-website/tracker';
import { useCanEdit } from '~/modules/root/hook';
import { PROFESSIONAL_URL } from '@modules/business-manager/config/constants';
import { fireNavMenuEvent } from './track-helpers';
import React from 'react';
import type { APHandle } from '~/modules/root';
import type { ProfessionalDetailRootLoaderData } from '../../page/ProfessionalPage';
import { Button as RoccoButton } from '@rocco/components/button';

const SubRouteNavigation: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const tracker = useTracker();
    const professionalInitial = useProfessionalInitialRouteData();
    const { linkedProfiles } =
        useLoaderData<ProfessionalDetailRootLoaderData>();

    const { URLSegment: profileURLSegment, ID: professionalId } =
        professionalInitial;
    const canEdit = useCanEdit(professionalId);

    const matches = useMatches();
    const isPreview = matches.some(
        (match) => (match?.handle as APHandle)?.isBMPreview
    );

    const currentRoute = matches[matches.length - 1]!.pathname;

    const items = getSubRoutes(
        professionalInitial,
        canEdit,
        linkedProfiles?.linkedProfilesList
    ).map((r) => {
        const prepend = isPreview
            ? PROFESSIONAL_URL.EDIT_MODE
            : PROFESSIONAL_URL.PUBLIC;
        const link = `${prepend}/${profileURLSegment}${r.link}`.replace(
            /\/+$/,
            ''
        );

        const item = {
            label: r.label,
            link,
        };

        const paths = currentRoute.split('/').filter(Boolean);
        const currentPath = paths[2] ? `/${paths[2]}` : `/`;
        const isActive = r.link === currentPath;

        return (
            <RoccoButton
                key={item.link}
                asChild
                color={isActive ? 'dark' : 'secondary'}
            >
                <Link
                    to={item.link}
                    prefetch={'intent'}
                    preventScrollReset
                    onClick={() => {
                        fireNavMenuEvent(item, window.location.href, tracker);
                    }}
                    className="text-sm leading-6.5 tracking-0 md:text-base md:tracking-0"
                >
                    {item.label}
                </Link>
            </RoccoButton>
        );
    });

    return (
        <div className="flex items-center gap-1.5 md:gap-2 bg-background">
            {items}
            {children}
        </div>
    );
};

export default SubRouteNavigation;
